import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { NextRouter, useRouter } from 'next/router';
import storeCreator from '../redux/setup/store';
import { Store } from 'redux';
import useCohesion from './useCohesion';
import { trackEvent } from '~/helpers/tracking';
import { setNewRelicCurrentRouteName, setNewRelicCustomAttribute, setNewRelicRelease } from '~/helpers/newrelic';
import fetch from '~/helpers/fetch';
import { calculateDays } from '~/helpers/calculateDays';
import { PAGE_ROUTES } from '../../src/constants/pages';
import clientSide from '~/helpers/clientSide';
import { resetActiveLegal } from '~/redux/actions/legal';
import { getUtmParamsFromCookie } from '~/helpers/getUtmParamsFromCookie';
import { setUtmParams } from '~/redux/actions/common';

const production = process.env.environment === 'production';

setNewRelicRelease(process.env.releaseName, process.env.release);

const store = storeCreator(production);

interface UseAppSetup {
  store: Store;
  production: boolean;
  router: NextRouter;
}

const useAppSetup = (): UseAppSetup => {
  const { monarch: monarchState, tracking: trackingState, resume, credit } = store.getState();
  const { lineItems } = credit?.billingSummary || {};
  const [tracking, setTracking] = useState(trackingState);
  const [monarch, setMonarch] = useState(monarchState);

  // Subscribe to the store to listen for changes since we are outside of the redux provider.
  // Once we have the tracking.sessionId stop listening for changes.
  useEffect(() => {
    const unsub = store.subscribe(() => {
      const state = store.getState();
      if (state.tracking) setTracking(state.tracking);
      if (state.monarch?.apiVersion) {
        setNewRelicCustomAttribute([{ name: 'apiVersion', value: state.monarch.apiVersion }]);
        Cookies.set('apiVersion', state.monarch?.apiVersion, { expires: 30 });
        setMonarch(state.monarch);
      }
    });
    if (tracking.sessionId && monarch?.apiVersion) unsub();
    return unsub;
  }, [tracking.sessionId, monarch]);

  useEffect(() => {
    if (!clientSide) return;
    // Set UTM params
    const utmParams = getUtmParamsFromCookie();
    if (utmParams) {
      store.dispatch(setUtmParams(utmParams));
      // Set UTM params in NewRelic for tracking
      setNewRelicCustomAttribute([
        { name: 'utm_term', value: utmParams.utm_term || '' },
        { name: 'utm_content', value: utmParams.utm_content || '' },
        { name: 'utm_pp', value: utmParams.utm_pp || '' },
      ]);
    }
  }, []); // Empty dependency array since we only want this to run once on mount

  useCohesion(store.dispatch, tracking);

  // NOTE: once resume has been built switch to the resume cookie.
  useEffect(() => {
    if (clientSide && tracking.sessionId && monarch.apiVersion && !Cookies.get('orderSessionStarted')) {
      setNewRelicCustomAttribute([{ name: 'orderSessionStartedCookieFound', value: 'false' }]);

      fetch({
        endpoint: `${monarch.apiVersion}/order/create`,
        options: {
          method: 'post',
          body: JSON.stringify({
            affiliateId: tracking.affiliateId || '',
            isResume: Boolean(resume?.enabled),
            isRecommendation: Boolean(router?.pathname?.slice(1)?.includes('my-custom-quote')),
          }),
        },
        tracking,
      });

      trackEvent({ action: 'orderSessionStarted' });
      Cookies.set('orderSessionStarted', 'true', { expires: 1 / 24 });

      setNewRelicCustomAttribute([{ name: 'orderSessionStartedCookieSet', value: 'true' }]);
    } else {
      setNewRelicCustomAttribute([{ name: 'orderSessionStartedCookieFound', value: 'true' }]);
      setNewRelicCustomAttribute([{ name: 'orderSessionStartedFired', value: 'false' }]);
    }
  }, [tracking.sessionId, monarch.apiVersion]);

  // Event tracking
  const router = useRouter();
  const currentPage = router.route.slice(1) || 'address-check';

  // page tracking.
  useEffect(() => {
    if (clientSide) document.body.dataset.page = router?.pathname?.slice(1) || 'address-check';
  }, [router.pathname]);

  useEffect(() => {
    const state = store.getState();
    const { address, products, tracking, appointment, resume } = state;
    const daysUntilAppointment = calculateDays(appointment?.date);

    store.dispatch(resetActiveLegal());

    setNewRelicCurrentRouteName(currentPage);

    const pageName = resume?.cartPage?.includes('credit')
      ? 'resume_credit'
      : resume?.cartPage?.includes('plan')
      ? 'resume_plan_package'
      : currentPage;

    trackEvent({
      action: 'pageView',
      data: {
        detail: {
          page: currentPage,
          pageName: pageName,
          zip: address?.install?.zip || '',
          products: [products?.selected?.internet?.productId, products?.selected?.voice?.productId].filter((id) => id),
          ropeId: tracking && tracking?.sessionId,
          id: tracking && tracking?.anonymousId,
          daysUntilAppointment,
          serviceability: (() => {
            let serviceability = '';
            if (currentPage !== PAGE_ROUTES.ADDRESS_CHECK) {
              serviceability = products?.activeTab?.copperProduct ? 'SERVICEABLE' : 'SERVICEABLE_FIBER';
            }
            return serviceability;
          })(),
          c2f: currentPage.includes('fiber'),
          resumeEnabled: monarch?.determineResumeQuote || false,
          lineItems: lineItems || {},
          internetId: products?.selected?.internet?.productId,
        },
      },
    });

    if (currentPage === 'address-check' && Cookies?.get('quoteId') && monarch?.determineResumeQuote === false) {
      trackEvent({
        action: 'elementViewed',
        data: {
          elementType: 'PAGE',
          position: pageName,
          text: 'resumeEnabled: false',
        },
      });
    }
  }, [currentPage]);

  useEffect(() => {
    store.dispatch(resetActiveLegal());
    if (currentPage !== 'confirmation') {
      trackEvent({
        action: 'cartEntry',
        data: {
          detail: {
            clientSessionId: tracking.clientSessionId,
            actionName: 'CartEntry',
            orderId: tracking.orderId,
          },
        },
      });
    }
  }, []);

  return {
    store,
    production,
    router,
  };
};

export default useAppSetup;
