import { BaseResponse } from './common';

export interface AudienceGroup {
  uid: string;
  name: string;
}

export interface MakeAudiences {
  audienceGroups: AudienceGroup[];
}

export interface Features {
  architectureType: string;
  exteriorType: string;
  fireplace: boolean;
  floorCount: number;
  garage: boolean;
  garageType: string;
  heating: boolean;
  heatingType: string;
  roofType: string;
  roomCount: number;
  foundationType: string;
  unitCount: number;
  cooling: boolean;
  coolingType: string;
}

export interface TaxAssessment {
  value: number;
  land: number;
  improvements: number;
}

export interface PropertyTaxes {
  total: number;
}

export interface TaxAssessments {
  [year: string]: TaxAssessment;
}

export interface PropertyTaxesByYear {
  [year: string]: PropertyTaxes;
}

export interface PropertyData {
  id: string;
  formattedAddress: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  county: string;
  latitude: number;
  longitude: number;
  propertyType: string;
  bedrooms: number;
  bathrooms: number;
  squareFootage: number;
  lotSize: number;
  yearBuilt: number;
  legalDescription: string;
  lastSaleDate: string;
  lastSalePrice: number;
  features: Features;
  taxAssessments: TaxAssessments;
  propertyTaxes: PropertyTaxesByYear;
  ownerOccupied: boolean;
}

export interface TipData {
  AverageHouseValue: number;
  Population: number;
  IncomePerHousehold: number;
  PersonsPerHousehold: number;
  MalePopulation: number;
  FemalePopulation: number;
  CBSAType: string;
  MedianAge: number;
  NumberOfBusinesses: number;
  NumberOfEmployees: number;
  WaterArea: number;
  LandArea: number;
}

export interface PropensityScores {
  modelType: string;
  userGigPlusClickIntent: number;
  userOrderIntent: number;
  userVasClickIntent: number;
  CUTOFF_SCORES: {
    user_gig_plus_click_intent: number;
    user_order_intent: number;
    user_vas_click_intent: number;
  };
}

export interface EnrichedCustomer {
  makeAudiences: MakeAudiences;
  propertyData: PropertyData;
  tipData: TipData;
  propensityScores: PropensityScores;
}

export interface EnrichCustomerResponse extends BaseResponse {
  metadata: Record<string, unknown>;
  results: EnrichedCustomer | null;
}

export enum ActionTypes {
  SET_ENRICHED_CUSTOMER = 'enrichedCustomer/set',
}

export interface SetEnrichedCustomerAction {
  type: ActionTypes.SET_ENRICHED_CUSTOMER;
  enrichedCustomer: EnrichedCustomer | Record<string, never>;
}

export type Actions = SetEnrichedCustomerAction;
