import clientSide from './clientSide';

// Checks to see if the user has a spanish language in their top 3 preferred languages or if they have the ljs=es-US query parameter in the URL
export function spanishPreferred(): Array<string> | boolean {
  if (clientSide) {
    const languagesArray = [...navigator?.languages];

    const searchParams = new URLSearchParams( window.location?.search || '' );
    const ljs = searchParams.get( 'ljs' );

    return languagesArray.slice(0, 3).some((language) => language.includes('es')) ||
    ljs === 'es-US';
  }
}
