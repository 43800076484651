import { useEffect, useRef, useState, MutableRefObject } from 'react';

/**
 * Hook to detect if an element is in the viewport
 * @param threshold - number between 0 and 1 representing the percentage of the element that must be in the viewport to be considered in view
 * @param fireOnce - if true, the hook will only fire once when the element enters the viewport and won't reset when it leaves
 * @param onInViewport - optional callback function to execute when the element enters the viewport
 * @returns { isIntersecting, ref } - boolean indicating if the element is in the viewport and a ref to assign to the element
 */
export default function useInViewport(
  threshold = 0,
  fireOnce = false,
  onInViewport?: () => void
): { isIntersecting: boolean; ref: MutableRefObject<HTMLDivElement | null> } {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const hasFiredRef = useRef(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && 'IntersectionObserver' in window) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            if (!hasFiredRef.current || !fireOnce) {
              setIsIntersecting(true);
              onInViewport?.();
              if (fireOnce) {
                hasFiredRef.current = true;
                observer.disconnect();
              }
            }
          } else if (!fireOnce) {
            setIsIntersecting(false);
          }
        },
        { threshold }
      );

      if (ref.current) {
        observer.observe(ref.current);
      }

      return () => {
        observer.disconnect();
      };
    } else {
      console.warn('IntersectionObserver is not supported in this environment.');
      setIsIntersecting(false);
    }
  }, [threshold, fireOnce, onInViewport]);

  return { isIntersecting, ref };
}
